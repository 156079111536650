<template>
  <div>
    <div v-show="step == 1">
      <div class="text-center mt-15" style="width: 100%">
        <v-progress-circular
          class="mb-8"
          color="primary"
          indeterminate
          size="32"
        />
        <br />
        <h2>Atualizando sua assinatura...</h2>
      </div>
    </div>
    <div class="text-center" v-show="step == 2">
      <div class="my-6">
        <v-img
          eager
          contain
          height="200"
          src="../../assets/images/boneca03.png"
        />
      </div>
      <div class="my-6">
        Sua assinatura foi concluída com sucesso.
        <h2>Obrigado por assinar o Psicoplanner!</h2>
      </div>
      <div class="my-6">
        <v-btn color="primary" @click="$router.push('/')()"> Prosseguir </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => ({
    step: 1,
  }),

  mounted() {
    this.showSession();
  },

  methods: {
    async showSession() {
      await this.$http.show(
        "subscription/checkout",
        this.$route.query.session_id
      );

      await this.$auth.getLoginData();

      this.step = 2;
    },
  },
};
</script>

<style></style>
